(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/swiper/assets/javascripts/react-swiper.js') >= 0) return;  svs.modules.push('/components/components/swiper/assets/javascripts/react-swiper.js');
"use strict";

const _excluded = ["scrollingX", "scrollingY", "disableOverflowScrolling", "className", "children"],
  _excluded2 = ["forwardedRef", "onReachedEnd", "onScroll"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
(function (svs, React) {
  'use strict';

  class ReactSwiper extends React.Component {
    constructor(props) {
      super(props);
      _defineProperty(this, "state", {
        isRefreshing: false
      });
      _defineProperty(this, "refreshTimeout", null);
      _defineProperty(this, "handleOrientationChange", () => {
        clearTimeout(this.refreshTimeout);
        if (!this.element.current) {
          return;
        }
        this.setState({
          isRefreshing: true
        }, () => {
          this.refreshTimeout = setTimeout(() => {
            this.setState({
              isRefreshing: false
            });
          }, svs.components.SwiperBase.orientationRefreshTimeout);
        });
      });
      _defineProperty(this, "handleScroll", event => {
        svs.components.SwiperBase.handleScroll(event, {
          reachedend: [this.props.onReachedEnd],
          scroll: [this.props.onScroll]
        }, this.element.current);
      });
      this.element = props.forwardedRef || React.createRef();
    }
    componentDidMount() {
      this.handleTouchStart = e => svs.components.SwiperBase.handleTouchStart(e, this.element.current);
      this.handleTouchMove = e => svs.components.SwiperBase.handleTouchMove(e, this.element.current, this.props.scrollingX);
      svs.core.detect.orientation.on('change', this.handleOrientationChange);
      this.element.current.addEventListener('touchstart', this.handleTouchStart);
      this.element.current.addEventListener('touchmove', this.handleTouchMove);
      this.element.current.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
      clearTimeout(this.refreshTimeout);
      svs.core.detect.orientation.off('change', this.handleOrientationChange);
      this.element.current.removeEventListener('touchstart', this.handleTouchStart);
      this.element.current.removeEventListener('touchmove', this.handleTouchMove);
      this.element.current.removeEventListener('scroll', this.handleScroll);
    }
    render() {
      const _this$props = this.props,
        {
          scrollingX,
          scrollingY,
          disableOverflowScrolling,
          className,
          children
        } = _this$props,
        otherProps = _objectWithoutProperties(_this$props, _excluded);
      const {
          forwardedRef,
          onReachedEnd,
          onScroll
        } = otherProps,
        externalProps = _objectWithoutProperties(otherProps, _excluded2);
      let styleAttributes = svs.components.SwiperBase.getReactStyleAttribute(scrollingX, scrollingY, disableOverflowScrolling);
      if (this.state.isRefreshing) {
        styleAttributes = {
          WebkitOverflowScrolling: 'auto',
          overflow: 'hidden'
        };
      }
      return React.createElement("div", _extends({
        className: className.concat(' js-swiper-container swiper-container'),
        ref: this.element,
        style: styleAttributes
      }, externalProps), children);
    }
  }
  ReactSwiper.defaultProps = {
    className: '',
    disableOverflowScrolling: false,
    onReachedEnd: () => {},
    onScroll: () => {},
    scrollingX: false,
    scrollingY: true
  };
  svs.components = svs.components || {};
  svs.components.ReactSwiper = ReactSwiper;
})(svs, React);

 })(window);